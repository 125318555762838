// SnackProfileUserNutritionCard.jsx
import React from "react";
import { FaUtensils, FaLeaf } from "react-icons/fa"; // Importa FaLeaf correctamente
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackProfileUserNutritionCard.css";

const SnackProfileUserNutritionCard = ({ nutritionItem }) => {
  // Valores predeterminados para evitar errores si faltan datos
  const {
    Alimento = "No especificado",
    Plan = "No especificado",
    PorcionGramo = "0",
    Observaciones = "No aplica",
  } = nutritionItem || {}; // Desestructuración segura con valores predeterminados

  return (
    <div className="card nutrition-card">
      <div className="icon-container">
        <FaUtensils size={48} className="food-icon" /> {/* Ícono alternativo */}
      </div>
      <div className="card-body">
        <h5 className="card-title">{Alimento}</h5>
        <div className="nutrition-details">
          <p>
            <FaLeaf /> Plan: {Plan}
          </p>
          <p>Porción: {PorcionGramo} gramos</p>
          <p className="observations">Observaciones: {Observaciones}</p>
        </div>
      </div>
    </div>
  );
};

export default SnackProfileUserNutritionCard;
