import React, { useEffect, useState } from "react";
import { useConfig } from "../../../../utensils/ConfigContext";
import { useUser } from "../../../../utensils/UserContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackUserEvaluation.css";

const SnackUserEvaluation = () => {
  const { apiToken } = useConfig();
  const { user } = useUser();
  const [evaluations, setEvaluations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const fetchEvaluations = async () => {
      if (!user?.Email || !apiToken) return;

      const url = `https://api.baserow.io/api/database/rows/table/240558/?user_field_names=true&search=${user.Email}`;
      console.log("URL para obtener evaluaciones:", url);

      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Token ${apiToken}` },
        });

        const evaluations = response.data.results;

        // Ordenar las evaluaciones por "Creado en"
        const sortedEvaluations = evaluations.sort((a, b) => {
          const dateA = new Date(a["Creado en"]);
          const dateB = new Date(b["Creado en"]);
          return dateB - dateA;
        });

        setEvaluations(sortedEvaluations);
      } catch (error) {
        console.error("Error fetching evaluations:", error);
      }
    };

    fetchEvaluations();
  }, [user, apiToken]);

  if (evaluations.length === 0) {
    return <p>Cargando evaluaciones...</p>;
  }

  const currentEvaluation = evaluations[currentPage];

  const formatDate = (dateString) => {
    if (!dateString) return "No disponible";

    const [year, month, day] = dateString.split("-");
    const parsedDate = new Date(year, month - 1, day);

    return parsedDate.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formattedCreatedDate = formatDate(currentEvaluation["Creado en"]);

  return (
    <div className="evaluation-list">
      <h4 className="mb-3">Evaluaciones del Usuario</h4>
      <div className="evaluation-card p-4 mb-4">
        <h5>Evaluación #{currentPage + 1}</h5>
        <p className="text-muted">
          Fecha de creación: {formattedCreatedDate || "No disponible"}
        </p>
        <div className="gender-container mb-4">
          <h5 className="me-3">Género:</h5>
          <FontAwesomeIcon
            icon={currentEvaluation.Genero === "Masculino" ? faMars : faVenus}
            className="gender-icon"
          />
          <span className="gender-text ms-2">{currentEvaluation.Genero}</span>
        </div>
        {/* Historial Médico */}
        <div className="medical-history">
          <h5>Historial Médico</h5>
          <ul className="list-unstyled">
            <li>
              Farmacológicos: {currentEvaluation.Farmacologicos ? "Sí" : "No"}
            </li>
            <li>Quirúrgicos: {currentEvaluation.Qirurgicos ? "Sí" : "No"}</li>
            <li>Patológicos: {currentEvaluation.Patologicos ? "Sí" : "No"}</li>
            <li>Familiares: {currentEvaluation.Familiares ? "Sí" : "No"}</li>
            <li>Sociales: {currentEvaluation.Sociales ? "Sí" : "No"}</li>
            <li>Traumáticos: {currentEvaluation.Traumaticos ? "Sí" : "No"}</li>
          </ul>
          <p>
            <strong>Observaciones:</strong>{" "}
            {currentEvaluation.Observaciones || "No disponible"}
          </p>
        </div>

        {/* Detalles de Evaluación */}
        <div className="evaluation-details">
          <h5>Detalles de la Evaluación</h5>
          <p>
            <strong>Género:</strong>{" "}
            {currentEvaluation.Genero || "No disponible"}
          </p>
          <p>
            <strong>Objetivo:</strong>{" "}
            {currentEvaluation.Objetivo || "No disponible"}
          </p>
          <p>
            <strong>Nivel:</strong> {currentEvaluation.Nivel || "No disponible"}
          </p>
          <p>
            <strong>Frecuencia:</strong>{" "}
            {currentEvaluation.Frecuencia || "No disponible"}
          </p>
          <p>
            <strong>Edad:</strong> {currentEvaluation.Edad || "No disponible"}{" "}
            años
          </p>
          <p>
            <strong>Talla:</strong> {currentEvaluation.Talla || "No disponible"}{" "}
            cm
          </p>
          <p>
            <strong>Peso:</strong>{" "}
            {currentEvaluation.Peso_kg || "No disponible"} kg
          </p>
          <p>
            <strong>Etnia:</strong> {currentEvaluation.Etnia || "No disponible"}{" "}
          </p>
          <p>
            <strong>Diseño:</strong>{" "}
            {currentEvaluation.diseno || "No disponible"}{" "}
          </p>

          {/* Nuevos campos */}
          <h5 className="mt-3">Métodos de Entrenamiento</h5>
          <p>
            <strong>FullBody:</strong>{" "}
            {currentEvaluation.FullBody ? "Sí" : "No"}
          </p>
          <p>
            <strong>TITS:</strong> {currentEvaluation.TITS ? "Sí" : "No"}
          </p>
          <p>
            <strong>Push Pull Leg:</strong>{" "}
            {currentEvaluation.PushPullLeg ? "Sí" : "No"}
          </p>
          <p>
            <strong>Weider:</strong> {currentEvaluation.Weider ? "Sí" : "No"}
          </p>
        </div>

        {/* Observaciones Finales */}
        <div className="final-observations mt-3">
          <h5>Observaciones FT</h5>
          <p>{currentEvaluation.ObservacionesFT || "No disponible"}</p>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls d-flex justify-content-between mt-3">
        <button
          className="btn btn-primary"
          disabled={currentPage === 0}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Anterior
        </button>
        <button
          className="btn btn-primary"
          disabled={currentPage === evaluations.length - 1}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default SnackUserEvaluation;
